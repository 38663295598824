<template>
  <v-row>
    <v-col cols="12" sm="12" md="4" class="text-left mt-4">
      <v-toolbar-title class="pl-5  pb-5">{{titleInfo}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-col>
    <v-col cols="8" sm="8" md="6" class="text-right pl-5">
      <v-text-field v-model="v_search" append-icon="mdi-magnify" label="Search" single-line hide-details clearable
        class="pl-5" />
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="4" sm="4" md="2" class="text-right pr-5">
      <v-btn class="white--text btn-custom mr-5" color="green darken-1" depressed @click="getData()">
        SEARCH
      </v-btn>
    </v-col>
  </v-row>

</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission', "user", "time"],
  data() {
    return {
      data: {
        descendingFormat: [true],
        sortByFormat: ['amount_win'],
        descending: true,
        sortBy: "amount_win",
        page: 1,
        rowsPerPage: 10,
        fields: ["_id.public_ip", "_id.app_id", "_id.com_id", "_id.com", "_id.con_balance", "_id.admin_id", "_id.admin", "_id.admin_balance", "_id.master_id", "_id.master", "_id.master_balance", "_id.agent_id", "_id.agent", "_id.agent_balance", "_id.player_id", "_id.player", "_id.player_balance", "amount", "amount_win"],
        search: '',
      },
      v_search: "",
    };
  },


  computed: {
    titleInfo: function () {
      if (this.user) {
        if (this.user.role_name == "CONTROLLER" || this.user.role_name == 'MANAGER' || this.user.role_name == 'SPECIAL-MANAGER') return "List Player Statement"
        else return "Loading..."
      }
      else return "Loading..."
    },
    reportUrl: function () {

      if (this.user) {
        if (this.user.role_name == "CONTROLLER" || this.user.role_name == 'MANAGER' || this.user.role_name == 'SPECIAL-MANAGER') return "getViewPlayerSummaryUSDByGroup"
        else return null
      }
      else return null
    },
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },
    async getData() {
      this.data.search = this.v_search
      let preData = {
        data: this.data,
        reportUrl: this.reportUrl
      }
      // console.log(preData)
      this.fetchListStatement(preData).then(() => {
      })
    },
    ...mapActions("$_ocControlStatementSummaryPlayerUSD", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.btn-custom {
  position: relative;
  // right: 250px;
  top: 10px;
}

.tab-filter {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: row;
}

.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}
</style>
